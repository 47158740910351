<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Nova Validação da BDGD"
      subtitle="Selecione o mês e entidades que serão validadas"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <validar-bdgd-wizard-tabs
        ref="validarBdgdParametros"
        :items="validacoes"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import ValidacoesBdgdsService from '@/services/ValidacoesBdgdsService';
import wizardMixins from '@/mixins/wizardMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins],
  components: {
    ValidarBdgdWizardTabs: () =>
      import('@/components/geracao-bdgd/validacoes-bdgd/ValidarBdgdWizardTabs'),
    MaterialWizard: () => import('@/components/base/MaterialWizard')
  },
  data: () => ({
    tabs: ['Mês dos Dados', 'Entidades', 'Testes'],
    loading: false,
    validacoes: {}
  }),
  methods: {
    save() {
      const data = this.$refs.validarBdgdParametros.exportData();
      this.loading = true;
      ValidacoesBdgdsService.save(data)
        .then(() => {
          this.$toast.success('Validação da BDGD salva com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['VALIDACOES_DA_BDGD_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar a Validação da BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
